import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { indigo, deepPurple, lightBlue } from '@material-ui/core/colors';

/*
Breakpoints:
    xs, extra-small: 0px
    sm, small: 600px
    md, medium: 960px
    lg, large: 1280px
    xl, extra-large: 1920px
*/

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    backgroundColors: {
      body: React.CSSProperties['color'];
      paper: React.CSSProperties['color'];
      appBar: React.CSSProperties['color'];
    };
    textColors: {
      default: React.CSSProperties['color'];
      primary: React.CSSProperties['color'];
      secondary: React.CSSProperties['color'];
      info: React.CSSProperties['color'];
      warn: React.CSSProperties['color'];
      error: React.CSSProperties['color'];
      success: React.CSSProperties['color'];
    };
    linkColors: {
      primary: React.CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    backgroundColors: {
      body: React.CSSProperties['color'];
      paper: React.CSSProperties['color'];
      appBar: React.CSSProperties['color'];
    };
    textColors: {
      default: React.CSSProperties['color'];
      primary: React.CSSProperties['color'];
      secondary: React.CSSProperties['color'];
      info: React.CSSProperties['color'];
      warn: React.CSSProperties['color'];
      error: React.CSSProperties['color'];
      success: React.CSSProperties['color'];
    };
    linkColors: {
      primary: React.CSSProperties['color'];
    };
  }
}

const createTheme = (themeName: string): Theme => {
  let darkMode = false;
  let defaultTextColor = '#263238';
  let primaryTextColor = '#263238';
  let secondaryTextColor = '#546e7a';
  let infoTextColor = '#2196f3';
  let warnTextColor = '#ff9800';
  let errorTextColor = '#f44336';
  let successTextColor = '#4caf50';
  let bodyBackgroundColor = '#f4f6f8';
  let paperBackgroundColor = '#ffffff';
  let appBarBackgroundColor = '#3949ab';
  let inputFocusedOutlineColor: string = indigo[500];
  let inputLabelFocusedOutlineColor: string = indigo[500];

  switch (themeName) {
    case 'dark':
      darkMode = true;
      defaultTextColor = '#f6f5f8';
      primaryTextColor = '#a67dff';
      secondaryTextColor = '#9699a4';
      bodyBackgroundColor = '#222431';
      paperBackgroundColor = '#2a2d3d';
      appBarBackgroundColor = deepPurple[600];
      inputFocusedOutlineColor = indigo[300];
      inputLabelFocusedOutlineColor = indigo[300];
      break;
    case 'light':
      darkMode = false;
      break;
    default:
    // all properties use the default values
  }

  const theme = createMuiTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
      primary: {
        main: indigo[500],
      },
      secondary: {
        main: deepPurple[800],
      },
    },
    textColors: {
      default: defaultTextColor,
      primary: primaryTextColor,
      secondary: secondaryTextColor,
      info: infoTextColor,
      warn: warnTextColor,
      error: errorTextColor,
      success: successTextColor,
    },
    backgroundColors: {
      body: bodyBackgroundColor,
      paper: paperBackgroundColor,
      appBar: appBarBackgroundColor,
    },
    linkColors: {
      primary: lightBlue[500],
    },
    overrides: {
      // BASELINE
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor: bodyBackgroundColor,
            color: defaultTextColor,
          },
        },
      },
      // TYPOGRAPHY
      MuiTypography: {
        h1: {
          fontSize: '2rem',
          marginBottom: '16px',
        },
        h2: {
          fontSize: '1.75rem',
          marginBottom: '16px',
        },
        h3: {
          fontSize: '1.5rem',
          marginBottom: '16px',
        },
        h4: {
          fontSize: '1.25rem',
          marginBottom: '8px',
        },
        h5: {
          fontSize: '1.125rem',
          marginBottom: '8px',
        },
        h6: {
          fontSize: '0.75rem',
          marginBottom: '8px',
          color: '#aaaaaa',
          fontWeight: 900,
          textTransform: 'uppercase',
        },
      },
      // PAPER
      MuiPaper: {
        root: {
          backgroundColor: paperBackgroundColor,
        },
      },
      // APP BAR
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: appBarBackgroundColor,
        },
      },
      // LIST
      MuiListSubheader: {
        root: {
          fontSize: '0.875rem',
          color: secondaryTextColor,
        },
      },
      MuiListItemText: {
        primary: {
          fontSize: '0.875rem',
          color: secondaryTextColor,
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: '32px',
          color: secondaryTextColor,
        },
      },
      // CARD
      MuiCardHeader: {
        title: {
          fontWeight: 500,
        },
        subheader: {
          fontSize: '0.875rem',
        },
      },
      // TextInput
      MuiOutlinedInput: {
        // '&> input': {
        //   color: '#f00',
        // },
        // '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        //   borderColor: '#f00',
        // },
        root: {
          '&$focused': {
            '& > $notchedOutline': {
              borderColor: inputFocusedOutlineColor,
            },
          },
        },
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: inputLabelFocusedOutlineColor,
          },
        },
      },
    },
  });

  return theme;
};

export const LightTheme = createTheme('light');
export const DarkTheme = createTheme('dark');
