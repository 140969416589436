import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Member, { Role } from '../../../../../models/member';
import {
	Box,
	Tab,
	Tabs,
	Typography,
} from '@material-ui/core';
import TabPanel from '../TabPanel';
import MemberUnlinkTab from './MemberUnlinkTab';
import MemberWithdrawalTab from './MemberWithdrawalTab';
import { useMutation, useQueryClient } from 'react-query';
import { StartDivorceWorkflowPayload } from '../../../../../lib/api/workflows';
import apiClient from '../../../../../lib/api';
import { showToastMessage } from '../../../../../store/slices/toastMessage';
import { useDispatch } from 'react-redux';
import useStyles from './styles';
import { DivorceDialogProps, DivorceDialogTabs } from './constants';
import ConfirmationTab from './ConfirmationTab';
import moment from 'moment';
import { LoadingButton } from '../../../../LoadingButton';


const DivorceDialog: React.FC<DivorceDialogProps> = ({
																											 open,
																											 onDialogClose,
																											 event,
																											 resetDialog,
																											 onUnlinkedMembersChanged,
																											 onWithdrawingMembersChanged,
																											 withdrawingMembers,
																											 unlinkedMembers,
																											 members,
																											 setDialogsOpen,
																											 initialDialogState,
																										 }) => {
	const theme = useTheme();
	const styles = useStyles();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [currentTab, setCurrentTab] = useState(DivorceDialogTabs.UNLINK_MEMBERS);
	const {membershipAccount} = event;
	const [hasWithdrawingMembers, setHasWithdrawingMembers] = useState<string>('');
	const [readOnlyHoHMembers, setReadOnlyHoHMembers] = useState<Member[]>([]);
	const [enrollmentEndDate, setEnrollmentEndDate] = useState<string>(moment().endOf('month').toISOString());
	const queryClient = useQueryClient();
	const dispatch = useDispatch();

	useEffect(() => {
		const tempMembers = members.map((member: Member) => {
			if (member.role === Role.AccountOwner) {
				return {...member, readonly: true};
			}
			return member;
		});
		setReadOnlyHoHMembers(tempMembers);
	}, [members]);

	const createDivorceRequest = useMutation((values: StartDivorceWorkflowPayload) => {
		return apiClient.workflows.startDivorceWorkflow(values);
	});

	const handleSubmit = () => {
		const payload = {
			membershipAccountUId: membershipAccount.uid,
			enrollmentEndDate,
			transitioningMembers: getTransitioningMembers().map(member => member.uid),
			withdrawingMembers,
		};

		createDivorceRequest.mutate(payload, {
			onSuccess: () => {
				queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
				dispatch(
					showToastMessage({
						message: `Divorce workflow started successfully${
							getTransitioningMembers().length > 1 ? '. KYC request email was sent to the member.' : ''
						}`,
						type: 'success',
					}),
				);
				resetDialog();
				setDialogsOpen({...initialDialogState, manualKycSteps: true});

			},
			onError: (error: any) => {
				dispatch(
					showToastMessage({
						message: `Divorce workflow failed to start. Contact the Help Desk to resolve the error.${
							error.message ? ` ${error.message}` : ''
						}`,
						type: 'error',
					}),
				);
			},
		});
	};

	/**
	 * Members moving to a new account, unlinked but not withdrawn
	 */
	const getTransitioningMembers = (): Member[] => {
		return members
			.filter((m: Member) => unlinkedMembers.includes(m.uid) && !withdrawingMembers.includes(m.uid));
	};

	/**
	 * The unlinked members and the account HOH can be withdrawn
	 */
	const getMemberEligibleForWithdrawal = (): Member[] => {
		return members
			.filter((m: Member) => m.role === Role.AccountOwner || unlinkedMembers.includes(m.uid));
	};

	/**
	 * Members staying on the current membership account, not withdrawn or unlinked
	 */
	const getRemainingMembers = (): Member[] => {
		const unlinkedAndWithdrawingMemberUIds = withdrawingMembers
			.concat(unlinkedMembers);

		return members
			.filter((m: Member) => !unlinkedAndWithdrawingMemberUIds.includes(m.uid));
	};

	const handleCloseDialog = () => {
		resetDialog();
		onDialogClose();
	};

	const onWithdrawingQuestionOptionChanged = (value: string) => {
		setHasWithdrawingMembers(value);
		// Resets withdrawn members if some had been selected
		onWithdrawingMembersChanged([]);
	};

	const handleNavigateForward = () => {
		if (currentTab === DivorceDialogTabs.UNLINK_MEMBERS) {
			unlinkedMembersTabNext();
		}
		if (currentTab === DivorceDialogTabs.WITHDRAW_MEMBERS) {
			withdrawMembersTabNext();
		}
	};

	const handleNavigateBack = () => {
		if (currentTab === DivorceDialogTabs.UNLINK_MEMBERS) {
			unlinkedMembersTabPrevious();
		}
		if (currentTab === DivorceDialogTabs.WITHDRAW_MEMBERS) {
			withdrawMembersTabPrevious();
		}
		if (currentTab === DivorceDialogTabs.CONFIRMATION) {
			confirmationTabPrevious();
		}
	};

	const unlinkedMembersTabNext = () => {
		if (hasWithdrawingMembers === 'yes') {
			setCurrentTab(DivorceDialogTabs.WITHDRAW_MEMBERS);
		} else {
			setCurrentTab(DivorceDialogTabs.CONFIRMATION);
		}
	};

	const withdrawMembersTabNext = () => {
		setCurrentTab(DivorceDialogTabs.CONFIRMATION);
	};

	const unlinkedMembersTabPrevious = () => {
		resetDialog();
	};

	const withdrawMembersTabPrevious = () => {
		setCurrentTab(DivorceDialogTabs.UNLINK_MEMBERS);
	};

	const confirmationTabPrevious = () => {
		setCurrentTab(hasWithdrawingMembers === 'no' ?
			DivorceDialogTabs.UNLINK_MEMBERS : DivorceDialogTabs.WITHDRAW_MEMBERS);
	};

	const onEnrollmentEndDateChanged = (value: string) => {
		setEnrollmentEndDate(value);
	};

	const isHasSelectedUnlinkedMembersThatIncludesSpouse = (): boolean => {
		const spouseMember = members.find((m: Member) => m.role === Role.Admin);

		if (!spouseMember) return false;

		return unlinkedMembers.length > 0 && unlinkedMembers.includes(spouseMember.uid);
	};

	const isHasSelectedWithdrawalMembers = (): boolean => {
		return withdrawingMembers.length > 0;
	};

	const isContinueDisabled = (): boolean => {
		if (currentTab === DivorceDialogTabs.UNLINK_MEMBERS) {
			return hasWithdrawingMembers === '' || !isHasSelectedUnlinkedMembersThatIncludesSpouse();
		} else if (currentTab === DivorceDialogTabs.WITHDRAW_MEMBERS) {
			return !isHasSelectedWithdrawalMembers();
		} else {
			return false;
		}
	};

	return (
		<Dialog
			fullScreen={fullScreen}
			open={open}
			onClose={handleCloseDialog}
			maxWidth="xl"
			disableBackdropClick
			scroll="paper"
		>
			<DialogTitle className={styles.dialogTitle} disableTypography={true}><Typography variant="h1"
																																											 className={styles.title}>Life
				Event:
				Divorce</Typography></DialogTitle>
			<DialogContent>
				<Box style={{
					flexGrow: 1,
					display: 'flex',
					height: 700,
					width: 1200,
				}}>
					<Tabs
						orientation="vertical"
						variant="scrollable"
						value={currentTab}
						indicatorColor="primary"
						className={styles.tabs}
					>
						<Tab
							label="Unlink"
							value={1}
							disabled={isContinueDisabled()}
							onClick={() => setCurrentTab(DivorceDialogTabs.UNLINK_MEMBERS)}
						/>
						<Tab
							label="Withdraw"
							disabled={isContinueDisabled() || hasWithdrawingMembers !== 'yes'}
							onClick={() => setCurrentTab(DivorceDialogTabs.WITHDRAW_MEMBERS)}
							value={2}
						/>
						<Tab
							label="Confirmation"
							disabled={isContinueDisabled()}
							onClick={() => setCurrentTab(DivorceDialogTabs.CONFIRMATION)}
							value={3}
						/>
					</Tabs>
					<TabPanel value={currentTab} index={DivorceDialogTabs.UNLINK_MEMBERS}>
						<MemberUnlinkTab
							members={readOnlyHoHMembers}
							onChange={onUnlinkedMembersChanged}
							selectedMembers={unlinkedMembers}
							onOptionChanged={onWithdrawingQuestionOptionChanged}
							hasWithdrawingMembers={hasWithdrawingMembers}
							hasSelectedUnlinkedMembers={isHasSelectedUnlinkedMembersThatIncludesSpouse}
							membershipAccount={membershipAccount}/>
					</TabPanel>
					<TabPanel value={currentTab} index={DivorceDialogTabs.WITHDRAW_MEMBERS}>
						<MemberWithdrawalTab
							members={getMemberEligibleForWithdrawal()}
							onChange={onWithdrawingMembersChanged}
							selectedMembers={withdrawingMembers}
							onEnrollmentEndDateChanged={onEnrollmentEndDateChanged}
							enrollmentEndDate={enrollmentEndDate}
							membershipAccount={membershipAccount}/>
					</TabPanel>
					<TabPanel value={currentTab} index={DivorceDialogTabs.CONFIRMATION}>
						<ConfirmationTab
							enrollmentEndDate={enrollmentEndDate}
							withdrawingMembers={members.filter((m: Member) => withdrawingMembers.includes(m.uid))}
							transitioningMembers={getTransitioningMembers()}
							remainingMembers={getRemainingMembers()}
						/>
					</TabPanel>
				</Box>
			</DialogContent>
			<DialogActions className={styles.dialogActions}>
				<Box className={styles.closeButtonWrapper}>
					<Button color="default" variant="contained" onClick={handleNavigateBack}>Back</Button>
				</Box>
				<Box className={styles.otherButtonsWrapper}>
					{
						currentTab !== DivorceDialogTabs.CONFIRMATION &&
              <Button color="primary" variant="contained" disabled={isContinueDisabled()}
                      onClick={handleNavigateForward}>Continue</Button>
					}
					{
						currentTab === DivorceDialogTabs.CONFIRMATION && <LoadingButton color="primary" variant="contained"
                                                                            loading={isContinueDisabled() || createDivorceRequest.isLoading}
                                                                            onClick={handleSubmit}>Confirm</LoadingButton>
					}
					<Button color="default" variant="contained" onClick={handleCloseDialog}>Close</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default DivorceDialog;