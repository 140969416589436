import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			textTransform: 'none',
		},
		dialogActions: {
			display: 'flex',
			justifyContent: 'space-evenly',
			margin: '20px',
		},
		closeButtonWrapper: {
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'flex-start',
		},
		otherButtonsWrapper: {
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'flex-end',
			gap: '20px',
		},
		tabs: {
			overflow: 'visible',
			'& .MuiTab-wrapper': {
				alignItems: 'flex-end',
			},
			borderRight: `1px solid ${theme.palette.divider}`,
		},
		dialogTitle: {
			margin: '20px 20px 0 20px',
		},
		subheading: {
			fontSize: '18px',
			letterSpacing: .15,
			margin: 0,
			fontWeight: 400,
			lineHeight: '21px',
			textTransform: 'none',
		},
		tabPanelRoot: {
			width: '100%',
		},
	}),
);

export default useStyles;