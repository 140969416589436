import React from 'react';
import {
	createStyles,
	FormControl,
	FormLabel,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import Spacer from '../../../../Spacer';
import { MemberSelector } from '../../../member';
import { DivorceDialogTabProps } from './constants';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment/moment';
import { MAX_CCM_PROGRAM_DATE } from '../../../../../lib/util';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface MemberWithdrawalTabProps extends DivorceDialogTabProps {
	onEnrollmentEndDateChanged: (value: string) => void;
	enrollmentEndDate: string;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		endDate: {
			marginTop: '.6rem',
			marginBottom: '.6rem',
		},
		title: {
			fontSize: '18px',
			letterSpacing: .15,
			margin: 0,
			fontWeight: 400,
			lineHeight: '21px',
			textTransform: 'none',
		},
	}),
);

const MemberWithdrawalTab: React.FC<MemberWithdrawalTabProps> = ({
																																	 members,
																																	 selectedMembers,
																																	 onChange,
																																	 membershipAccount,
																																	 onEnrollmentEndDateChanged,
																																	 enrollmentEndDate,
																																 }) => {
	const styles = useStyles();

	const handleEnrollmentEndDateSelected = (
		date: MaterialUiPickersDate,
	) => {
		if (date) {
			const dateString = date.toDateString();
			onEnrollmentEndDateChanged(dateString);
		}
	};

	return (
		<>
			<Typography variant="h4">Withdraw Members</Typography>
			<Spacer size={8}/>
			<Typography variant="body2">
				Choose which members to withdraw and choose the last day of enrollment.
				Program coverage will remain effective through 11:59:59 PM on the end date chosen.
			</Typography>
			<Spacer size={32}/>
			<FormControl variant="outlined">
				<FormLabel>
					<Typography className={styles.title} variant="h5">Enrollment End Date</Typography>
				</FormLabel>
				<Spacer size={16}/>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						disableToolbar
						value={moment(enrollmentEndDate).toDate()}
						inputVariant="outlined"
						variant="inline"
						onChange={date => handleEnrollmentEndDateSelected(date)}
						format="MM/dd/yyyy"
						label="End Date"
						className={styles.endDate}
						maxDate={moment(MAX_CCM_PROGRAM_DATE)}
						maxDateMessage={`The end date cannot be after ${moment(MAX_CCM_PROGRAM_DATE).format(
							'LL',
						)}`}
					/>
				</MuiPickersUtilsProvider>
			</FormControl>
			<Spacer size={40}/>
			<MemberSelector
				titleVariant="h5"
				members={members}
				selectedMemberUids={selectedMembers}
				onChange={onChange}
				membershipAccount={membershipAccount}/>
		</>);
};

export default MemberWithdrawalTab;