import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Box, createStyles, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertProps, Skeleton } from '@material-ui/lab';
import { Action, Subject, Roles } from '@ccm-innovation/auth-membership-service';

import Doomsday from '../../../components/admin/Doomsday';
import apiClient from '../../../lib/api';
import MembershipCancellation from '../../../components/membership/cancellation/MembershipCancellation';
import Page, { Breadcrumb } from '../../../components/Page';
import ApplicationError from '../../../components/ApplicationError';
import AccountDetails from '../../../components/membership/AccountDetails';
import AccountMembers from '../../../components/membership/AccountMembers';
import Member, { TransitioningMember } from '../../../models/member';
import MemberModal from '../../../components/membership/MemberModal';
import MemberProgram from '../../../models/memberProgram';
import ProgramEnrollment from '../../../models/programEnrollment';
import AccountContactInfo from '../../../components/membership/AccountContactInfo';
import MembershipAccount from '../../../models/membershipAccount';
import CustomError from '../../../lib/customError';
import NotFound from '../../../components/NotFound';
import AuditEvents from '../../../components/membership/account/audit-events/AuditEvents';
import StatusBadge, { getStatusVariant } from '../../../components/StatusBadge';
import MemberProgramModal from '../../../components/membership/account/program/MemberProgramModal';
import MemberPrograms from '../../../components/membership/account/program/MemberPrograms';
import ProgramChangeDialog from '../../../components/membership/account/program/ProgramChangeDialog';
import ProgramUpdateDialog from '../../../components/membership/account/program/update/ProgramUpdateDialog';
import MembershipReactivationModal from '../../../components/membership/reactivation/MembershipReactivationDialog';
import AdministrativeAddOn from '../../../components/membership/add-on/Administrative';
import MembershipWithdrawal from '../../../components/membership/withdrawal/MembershipWithdrawal';
import AccountActions from '../../../components/membership/account/AccountActions';
import { removeUnderscoresFromString } from '../../../lib/util';
import AccountTimeline from '../../../components/membership/account/timeline/AccountTimeline';
import { useSession } from '../../../context/session';
import ChangeGroup from '../../../components/membership/account/group/ChangeGroup';
import ProgramDeleteDialog from '../../../components/membership/account/program/deletion/ProgramDeleteDialog';
import CreateBillingRecords from '../../../components/membership/account/billing/CreateBillingRecords';
import PrintRequestView from '../../../modules/print-requests/views/PrintRequest.view';
import AccountErrors from '../../../components/membership/account/AccountErrors';
import LifeEvents from '../../../components/membership/account/workflows/LifeEvents';
import WorkflowInstance, { WorkflowTask } from '../../../models/workflowInstance';
import { Role as MemberRole } from '../../../models/member';
import ManualKycStepsRequiredDialog
  from '../../../components/membership/account/workflows/ManualKycStepsRequiredDialog';

interface Params {
  uid: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: { height: '32px' },
    pageTitle: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export type DialogState = {
  addOn: boolean
  withdrawal: boolean
  cancellation: boolean
  changeGroup: boolean
  createBillingRecords: boolean
  lifeEvents: boolean
  manualKycSteps: boolean
};

const initialDialogState = {
  addOn: false,
  withdrawal: false,
  cancellation: false,
  changeGroup: false,
  createBillingRecords: false,
  lifeEvents: false,
  manualKycSteps: false
} as DialogState;

function getKYCStatusForMember(
  memberUId: string,
  workflowInstance: WorkflowInstance,
): string | null {
  // Step 1: Filter the task states for the specific task names: "determine-kyc-check-status" or "perform-kyc-check"
  const workflowTasks = workflowInstance?.state;

  if (workflowTasks.length > 0) {
    const kycTask = workflowTasks.find(
      (task: WorkflowTask) =>
        (task?.props?.name === 'determine-existing-kyc-check' ||
          task?.props?.name === 'perform-kyc-check') &&
        task?.props?.metadata?.props?.outputs?.kycResult,
    );

    // Step 2: If a matching KYC task is found, retrieve the KYC status
    if (kycTask && kycTask.props?.metadata?.props?.outputs?.kycResult) {
      const kycStatus = kycTask.props?.metadata?.props?.outputs?.kycResult;

      // Step 3: Check if there is a transitioning member with the role of "admin" and matching memberUId
      const transitioningMember = workflowInstance?.metadata?.transitioningMembers?.find(
        (member: TransitioningMember) =>
          member?.memberUId === memberUId && member?.role === 'admin',
      );

      // If the transitioning member is found and is an admin, return the KYC status
      if (transitioningMember) {
        return kycStatus;
      }
    }
  }

  // If no matching member or no KYC result from the specified tasks, return null
  return null;
}

export default function MembershipAccountView(props: any) {
  const styles = useStyles();
  const { user } = useSession();
  const { uid }: Params = useParams();
  const [snackbarType] = useState<AlertProps['severity']>('success');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  // used for rendering individual member details modal
  const [memberModalOpen, setMemberModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('Add Member');
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [selectedMembersEnrollments, setSelectedMembersEnrollments] = useState<ProgramEnrollment[]>(
    [],
  );
  const [memberTabValue, setMemberTabValue] = React.useState(0); // TODO: this might be deprecated
  const [selectedDate, setSelectedDate] = useState<Date | null>(null); // TODO: this might be deprecated
  const [gender, setGender] = useState(''); // TODO: this might be deprecated
  const [role, setRole] = useState(''); // TODO: this might be deprecated
  const [showUpdateProgram, setShowUpdateProgram] = useState(false);
  const [showChangeProgram, setShowChangeProgram] = useState(false);
  const [selectedMemberProgram, setSelectedMemberProgram] = useState<MemberProgram | null>(null);
  const [memberProgramModalOpen, setMemberProgramModalOpen] = useState(false);
  const [showReactivateProgram, setShowReactivateProgram] = useState(false);
  const [showDeleteProgram, setShowDeleteProgram] = useState(false);
  const [priorEnrollments, setPriorEnrollments] = useState<ProgramEnrollment[] | null>(null);
  const [dialogsOpen, setDialogsOpen] = useState(initialDialogState);

  /**
   * Account fetch and save now use react-query and are no longer dependent on thunks or redux
   * See https://react-query.tanstack.com/overview for more information
   */
  const queryClient = useQueryClient();
  const accountFetch = useQuery(['membershipAccount', uid], () => {
    return apiClient.membershipAccounts.get(uid, {
      includeMembers: true,
      includeMemberPrograms: true,
      includeProgramEnrollments: true,
      includeAddresses: true,
      includeEvents: true,
      expand: 'group',
    });
  });
  const isLoading = accountFetch.isLoading;
  const accountFetchError = accountFetch.error;
  const membershipAccount: MembershipAccount | undefined = accountFetch.data;
  let member: Member | undefined = undefined;
  if (membershipAccount) {
    member = membershipAccount.members?.find((member: any) => member.role === MemberRole.Admin);
  }

  const [KYCStatus, setKYCStatus] = useState('');

  const kycStatusFetch = useQuery([membershipAccount, member], async () => {
    try {
      const workflowInstance = await apiClient.membershipAccounts.getLifeEventWorkflowInstanceInProgress(
        membershipAccount ? membershipAccount.uid : '',
      );
      if (workflowInstance) {
        return getKYCStatusForMember(member ? member.uid : '', workflowInstance);
      }
    } catch (err: any) {
      return null;
    }
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      queryClient.invalidateQueries([membershipAccount, member]);
    }, 5000);
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    if (kycStatusFetch && typeof kycStatusFetch.data === 'string') {
      setKYCStatus(kycStatusFetch.data);
    }
  }, [kycStatusFetch]);

  // This helps to update the selectedMember when performing an update in the member modal
  useEffect(() => {
    if (selectedMember && membershipAccount) {
      let member = membershipAccount.members?.filter(
        (member: any) => member.uid === selectedMember.uid,
      )[0];
      if (member) setSelectedMember(member);
    }
  }, [membershipAccount, selectedMember]);

  const breadcrumbs: Breadcrumb[] = [
    { href: '/', label: 'Home' },
    { href: '#', label: 'Membership' },
    { href: '/membership/accounts', label: 'Membership Accounts' },
    { label: isLoading ? 'Loading...' : membershipAccount?.accountName || '' },
  ];

  const handleMemberClose = () => {
    setMemberModalOpen(false);
    setTimeout(() => {
      setMemberTabValue(0);
    }, 750);
  };

  // TODO: this might be deprecated
  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRole(event.target.value as string);
  };

  // TODO: this might be deprecated
  const handleGenderChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGender(event.target.value as string);
  };

  const handleMemberOpen = (uid?: string) => {
    if (uid && membershipAccount!.members) {
      setMemberModalOpen(false);
      setModalTitle('Member Information');
      let member = membershipAccount!.members.filter((member: any) => member.uid === uid)[0];

      // set the member's programs
      setMembersPrograms(member);
      setSelectedMember(uid ? member : null);
    } else {
      setModalTitle('Add Member');
    }
    setMemberModalOpen(true);
  };

  const setMembersPrograms = (member: Member): void => {
    // get member's enrollments
    const programEnrollments = membershipAccount!.programEnrollments?.filter(
      (programEnrollment: ProgramEnrollment) => {
        const memberProgram = membershipAccount!.memberPrograms?.find(
          (memberProgram: MemberProgram) => {
            return programEnrollment.memberProgram === memberProgram.uid;
          },
        );
        if (memberProgram !== undefined) {
          programEnrollment.memberProgram = memberProgram;
        }

        return typeof programEnrollment.member === 'string'
          ? programEnrollment.member === member.uid
          : programEnrollment.member.uid === member.uid;
      },
    );
    setSelectedMembersEnrollments(programEnrollments ? programEnrollments : []);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Show not found or application error if fetch is unsuccessful
  if (accountFetchError && (accountFetchError as CustomError).status === 404) {
    return <NotFound />;
  } else if (accountFetchError) {
    return <ApplicationError />;
  }

  const onMembershipAccountUpdated = () => {
    queryClient.invalidateQueries(['membershipAccount', uid]);
  };

  const handleMemberProgramOpen = (selectedProgram: MemberProgram) => {
    setSelectedMemberProgram(selectedProgram);
    setMemberProgramModalOpen(true);
  };

  const handleMemberProgramClose = () => {
    setMemberProgramModalOpen(false);
    setSelectedMemberProgram(null);
  };

  const showProgramChangeDialog = () => {
    setShowChangeProgram(true);
  };

  const showProgramUpdateDialog = () => {
    setShowUpdateProgram(true);
  };

  const showDeleteProgramDialog = () => {
    setShowDeleteProgram(true);
  };

  const showReactivateProgramDialog = (priorEnrollments: ProgramEnrollment[]) => {
    setPriorEnrollments(priorEnrollments);
    setShowReactivateProgram(true);
  };

  const openMemberWithdrawalDialog = (member: Member | null = null) => {
    setSelectedMember(member);
    setDialogsOpen({ ...initialDialogState, withdrawal: true });
  };

  const handleCloseMemberWithdrawalDialog = () => {
    setDialogsOpen({ ...initialDialogState });
  };

  const onAccountActionDialogClose = () => {
    setDialogsOpen({ ...initialDialogState });
  };

  // check permissions
  if (user && user.cannot(Action.View, Subject.MembershipAccount)) {
    return <Redirect to={{ pathname: '/not-found' }} />;
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={
        <Box className={styles.pageTitle}>
          <div style={{ minWidth: 300, display: 'flex' }}>
            <Typography style={{ marginBottom: 0, marginRight: 10 }} variant="h1">
              {isLoading ? <Skeleton animation="wave" /> : membershipAccount?.accountName}
            </Typography>
            {membershipAccount?.status && (
              <div>
                <StatusBadge
                  color="success"
                  variant={getStatusVariant(membershipAccount.status)}
                  label={removeUnderscoresFromString(membershipAccount.status)}
                />
              </div>
            )}
            {membershipAccount?.archived && (
              <div style={{ marginLeft: '4px' }}>
                <StatusBadge color="warning" variant="warn" label="archived" />
              </div>
            )}
          </div>
          {membershipAccount && (
            <>
              <div>
                <Grid container spacing={2} direction="row">
                  {user && user.hasRole(Roles.SystemAdmin) && (
                    <Grid item>
                      <Doomsday membershipAccount={membershipAccount} />
                    </Grid>
                  )}
                  {user && user.can(Action.Update, Subject.MembershipAccount) && (
                    <Grid item>
                      <AccountActions
                        membershipAccount={membershipAccount}
                        setDialogsOpen={setDialogsOpen}
                        initialDialogState={initialDialogState}
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
              {/* Account Action dialogs */}
              <MembershipCancellation
                open={dialogsOpen.cancellation}
                onDialogClose={onAccountActionDialogClose}
                membershipAccount={membershipAccount}
              />
              <AdministrativeAddOn
                open={dialogsOpen.addOn}
                onDialogClose={onAccountActionDialogClose}
                membershipAccount={membershipAccount}
              />
              <MembershipWithdrawal
                open={dialogsOpen.withdrawal}
                onDialogClose={onAccountActionDialogClose}
                membershipAccount={membershipAccount}
                closeMemberWithdrawalDialog={handleCloseMemberWithdrawalDialog}
                selectedMember={selectedMember}
                setSelectedMember={setSelectedMember}
              />
              <ChangeGroup
                open={dialogsOpen.changeGroup}
                onDialogClose={onAccountActionDialogClose}
                membershipAccount={membershipAccount}
              />
              <ManualKycStepsRequiredDialog
                onDialogClose={onAccountActionDialogClose}
                open={dialogsOpen.manualKycSteps}
              />
              {user &&
                (user.hasRole(Roles.SystemAdmin) ||
                  user.hasRole(Roles.BillingAdmin) ||
                  user.hasRole(Roles.GroupAdmin) ||
                  user.hasRole(Roles.BillingUser)) && (
                  <CreateBillingRecords
                    open={dialogsOpen.createBillingRecords}
                    onDialogClose={onAccountActionDialogClose}
                    membershipAccount={membershipAccount}
                  />
                )}
              {user &&
                (user.hasRole(Roles.SystemAdmin) ||
                  user.hasRole(Roles.BillingAdmin) ||
                  user.hasRole(Roles.BillingUser)) && (
                  <LifeEvents
                    membershipAccount={membershipAccount}
                    onDialogClose={onAccountActionDialogClose}
                    setDialogsOpen={setDialogsOpen}
                    initialDialogState={initialDialogState}
                    open={dialogsOpen.lifeEvents}
                  />
                )}
            </>
          )}
        </Box>
      }
    >
      {membershipAccount?.errors && <AccountErrors errors={membershipAccount?.errors} />}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={1000}
      >
        <Alert severity={snackbarType}>{snackbarMessage}</Alert>
      </Snackbar>

      {/* Account Details Section */}
      <Grid container spacing={2} direction="row">
        <Grid item lg={4} md={5} xs={12}>
          {isLoading ? (
            <Skeleton animation="wave" height={300} />
          ) : (
            <AccountDetails
              membershipAccount={membershipAccount}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarOpen={setSnackbarOpen}
            />
          )}
        </Grid>
        <Grid item lg={8} md={7} xs={12}>
          {isLoading ? (
            <Skeleton animation="wave" height={300} />
          ) : (
            membershipAccount && (
              <AccountContactInfo
                membershipAccount={membershipAccount}
                membershipAccountUpdated={onMembershipAccountUpdated}
              />
            )
          )}
        </Grid>
      </Grid>

      <div className={styles.spacer} />
      <Divider />
      <div className={styles.spacer} />

      {/*Member Programs Section*/}
      {!isLoading && membershipAccount && (
        <>
          {user && user.can(Action.View, Subject.MemberProgram) && (
            <MemberPrograms
              handleMemberProgramOpen={handleMemberProgramOpen}
              handleMemberProgramClose={handleMemberProgramClose}
              membershipAccount={membershipAccount}
            />
          )}
          <MemberProgramModal
            handleMemberProgramClose={handleMemberProgramClose}
            memberProgramOpen={memberProgramModalOpen}
            selectedMemberProgram={selectedMemberProgram}
            showProgramChangeDialog={showProgramChangeDialog}
            showProgramUpdateDialog={showProgramUpdateDialog}
            membershipAccount={membershipAccount}
            showReactivateProgramDialog={showReactivateProgramDialog}
            showDeleteProgramDialog={showDeleteProgramDialog}
          />
          {selectedMemberProgram && showChangeProgram && (
            <ProgramChangeDialog
              open={showChangeProgram}
              toggleShowDialog={() => {
                setShowChangeProgram(!showChangeProgram);
              }}
              membershipAccount={membershipAccount}
              memberProgram={selectedMemberProgram}
              onProgramChanged={onMembershipAccountUpdated}
            />
          )}
          {selectedMemberProgram && showUpdateProgram && (
            <ProgramUpdateDialog
              open={showUpdateProgram}
              toggleShowDialog={() => {
                setShowUpdateProgram(!showUpdateProgram);
              }}
              membershipAccount={membershipAccount}
              memberProgram={selectedMemberProgram}
              onProgramUpdated={() => {
                setMemberProgramModalOpen(false);
                setSelectedMemberProgram(null);
                onMembershipAccountUpdated();
              }}
              switchProgram={() => {
                setShowUpdateProgram(false);
                setShowChangeProgram(true);
              }}
            />
          )}
          {selectedMemberProgram &&
            showDeleteProgram &&
            user &&
            user.can(Action.Destroy, Subject.MemberProgram) && (
              <ProgramDeleteDialog
                open={showDeleteProgram}
                toggleShowDialog={() => {
                  setShowDeleteProgram(!showDeleteProgram);
                }}
                memberProgram={selectedMemberProgram}
                handleMemberProgramClose={handleMemberProgramClose}
                membershipAccountUpdated={onMembershipAccountUpdated}
              />
            )}
        </>
      )}

      <div className={styles.spacer} />
      <Divider />
      <div className={styles.spacer} />

      {/* Account Members Section */}
      {!isLoading &&
        membershipAccount &&
        membershipAccount.members &&
        membershipAccount.programEnrollments &&
        user &&
        user.can(Action.View, Subject.Member) && (
          <>
            <AccountMembers
              handleMemberOpen={handleMemberOpen}
              membershipAccount={membershipAccount}
              KYCStatus={KYCStatus}
            />
            <MemberModal
              gender={gender} // TODO: this might be deprecated
              handleDateChange={handleDateChange} // TODO: this might be deprecated
              handleGender={handleGenderChange} // TODO: this might be deprecated
              handleMemberClose={handleMemberClose}
              handleRoleChange={handleRoleChange}
              memberModalOpen={memberModalOpen}
              membershipAccount={membershipAccount} // TODO: this might be deprecated
              membershipAccountUid={membershipAccount.uid}
              memberTabValue={memberTabValue} // TODO: this might be deprecated
              modalTitle={modalTitle}
              openMemberWithdrawalDialog={openMemberWithdrawalDialog}
              role={role} // TODO: this might be deprecated
              selectedDate={selectedDate} // TODO: this might be deprecated
              selectedMember={selectedMember}
              selectedMembersEnrollments={selectedMembersEnrollments}
              setSelectedMember={setSelectedMember}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarOpen={setSnackbarOpen}
            />
          </>
        )}

      <div className={styles.spacer} />
      <Divider />
      <div className={styles.spacer} />

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={membershipAccount?.events && membershipAccount.events?.length > 0 ? 7 : 12}
        >
          <PrintRequestView membershipAccountUId={uid} />
        </Grid>
        <Grid item xs={12} md={5}>
          {/* Account Timeline Section */}
          {membershipAccount?.events && membershipAccount.events?.length > 0 && (
            <AccountTimeline events={membershipAccount.events}></AccountTimeline>
          )}
        </Grid>
      </Grid>

      <div className={styles.spacer} />
      <Divider />
      <div className={styles.spacer} />

      {/* Audit Events Section */}
      {user && user.can(Action.View, Subject.AuditEvent) && <AuditEvents membershipAccount={uid} />}

      {/* Account Reactivation Modal */}
      {membershipAccount && selectedMemberProgram && priorEnrollments && (
        <MembershipReactivationModal
          membershipAccount={membershipAccount}
          selectedMemberProgram={selectedMemberProgram}
          open={showReactivateProgram}
          onClose={() => {
            setShowReactivateProgram(false);
            handleMemberProgramClose();
          }}
        />
      )}
    </Page>
  );
}
