import DateFnsUtils from '@date-io/date-fns';
import { Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormikProps } from 'formik';
import moment from 'moment';
import React, { useMemo } from 'react';
import {
  getStartDateFromCurrentProgramEnrollment,
  MAX_CCM_PROGRAM_DATE,
} from '../../../../../lib/util';
import Member from '../../../../../models/member';
import MembershipAccount from '../../../../../models/membershipAccount';
import Spacer from '../../../../Spacer';
import { MemberSelector } from '../../../member';
import { DeathForm } from './DeathDialog';

export const WithdrawingMemberSelector: React.FC<{
  form: FormikProps<DeathForm>;
  survivingMembers: Member[];
  withdrawingMembers: Member[];
  disabledWithdrawingMemberUids: string[];
  hasNoSurvivingAdultMembers: boolean;
  membershipAccount: MembershipAccount;
  isInvalid: (hasError: boolean) => void;
}> = ({
  form,
  survivingMembers,
  withdrawingMembers,
  disabledWithdrawingMemberUids,
  hasNoSurvivingAdultMembers,
  membershipAccount,
  isInvalid,
}) => {
  /**
   * Get the earliest date to withdraw members
   *  The earliest they can be withdrawn is the latest start date of all the
   *  active program enrollments from all the members selected to be withdrawn
   */
  const earliestWithdrawalDate = useMemo(() => {
    const selectedProgramEnrollments = membershipAccount.programEnrollments!.filter(enrollment =>
      withdrawingMembers.some(member => {
        if (typeof enrollment.member === 'string') {
          return (
            enrollment.member === member.uid &&
            ((enrollment.ended && moment(enrollment.ended).isAfter(new Date())) ||
              enrollment.ended === null)
          );
        }

        return (
          (enrollment.member as Member).uid === member.uid &&
          ((enrollment.ended && moment(enrollment.ended).isAfter(new Date())) ||
            enrollment.ended === null)
        );
      }),
    );

    const earliestWithdrawalEndDate = getStartDateFromCurrentProgramEnrollment(
      selectedProgramEnrollments,
    );

    return earliestWithdrawalEndDate;
  }, [withdrawingMembers, membershipAccount.programEnrollments]);

  return (
    <>
      <Typography variant="h4">Withdraw Members</Typography>
      <Spacer size={8} />
      <Typography variant="body2">
        Choose which members to withdraw and choose the last day of enrollment. Program coverage
        will remain effective through 11:59:59 PM on the end date chosen.
      </Typography>
      <Spacer size={32} />
      <Typography variant="h5">Enrollment End Date</Typography>
      <Spacer size={16} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          value={
            hasNoSurvivingAdultMembers
              ? moment(form.values.dateOfDeath).endOf('month').toDate()
              : form.values.enrollmentEndDate
          }
          inputVariant="outlined"
          variant="inline"
          onChange={date => form.setFieldValue('enrollmentEndDate', date)}
          format="MM/dd/yyyy"
          label="End Date"
          minDate={moment(earliestWithdrawalDate)}
          maxDate={moment(MAX_CCM_PROGRAM_DATE)}
          minDateMessage={`The end date cannot be before ${moment(earliestWithdrawalDate).format(
            'LL',
          )}`}
          maxDateMessage={`The end date cannot be after ${moment(MAX_CCM_PROGRAM_DATE).format(
            'LL',
          )}`}
          disabled={hasNoSurvivingAdultMembers}
          onError={error => isInvalid(!!error)}
        />
      </MuiPickersUtilsProvider>
      <Spacer size={40} />
      <MemberSelector
        title="Members"
        titleVariant="h5"
        members={survivingMembers}
        selectedMemberUids={form.values.withdrawingMemberUids}
        disabledMemberUids={disabledWithdrawingMemberUids}
        shouldDisableSelectAll={hasNoSurvivingAdultMembers}
        membershipAccount={membershipAccount}
        onChange={selectedMemberUids =>
          form.setFieldValue('withdrawingMemberUids', selectedMemberUids)
        }
      />
    </>
  );
};
