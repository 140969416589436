import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import Member from '../../../models/member';
import MemberCard from './MemberCard';
import Spacer from '../../Spacer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    membersContainer: {
      display: 'flex',
      gap: '10px',
      flexWrap: 'wrap',
      '& .MuiCardContent-root': {
        minWidth: '300px',
      },
    },
    title: {
      fontSize: '18px',
      letterSpacing: 0.15,
      margin: 0,
      fontWeight: 400,
      lineHeight: '21px',
      textTransform: 'none',
    },
    header: {
      display: 'flex',
      justifyContent: 'start',
      marginBottom: '10px',
    },
  }),
);

interface MemberListProps {
  members: Member[];
  title?: string;
  titleVariant?: Variant;
	subtitle?: string;
}

export const MemberList: React.FC<MemberListProps> = ({ members, title, titleVariant = 'h6', subtitle }) => {
  const styles = useStyles();

  return members.length > 0 ? (
    <>
      {title && (
        <Box className={styles.header}>
          <Typography variant={titleVariant} className={titleVariant ? undefined : styles.title}>
            {title}
          </Typography>
        </Box>
      )}
			{
				subtitle &&
          <Box>
            <Spacer size={20}/>
              <Typography variant="h6">{subtitle}</Typography>
          </Box>
			}
      <Box className={styles.membersContainer}>
        {members.map((member: Member, index: number) => (
          <MemberCard key={index} member={member} selectable={false} summary={true} />
        ))}
      </Box>
    </>
  ) : (
    <Alert severity="info">There are no members to list</Alert>
  );
};
