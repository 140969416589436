import { Alert, AlertTitle } from '@material-ui/lab';
import Spacer from '../../../../Spacer';
import { MemberList } from '../../../member/MemberList';
import { Typography } from '@material-ui/core';
import moment from 'moment/moment';
import React from 'react';
import Member from '../../../../../models/member';
import useStyles from './styles';

interface ConfirmationTabProps {
	withdrawingMembers: Member[];
	enrollmentEndDate: string;
	transitioningMembers: Member[];
	remainingMembers: Member[];
}

const ConfirmationTab: React.FC<ConfirmationTabProps> = ({
																													 withdrawingMembers,
																													 enrollmentEndDate,
																													 transitioningMembers,
																													 remainingMembers,
																												 }) => {
	const styles = useStyles();

	return (<>
		{
			transitioningMembers.length < 1 ? <>
					<Alert severity="success" style={{width: '954px'}}
								 variant="outlined">
						<AlertTitle>No KYC check required</AlertTitle>
						No change to account owner on record.
					</Alert>
					<Spacer size={32}/>
				</>
				:
				<>
					<Alert severity="warning" style={{width: '954px'}}
								 variant="outlined">
						<AlertTitle>New Share Account Required</AlertTitle>
						Continue to request KYC check for transitioning spouse.
					</Alert>
					<Spacer size={32}/>
				</>
		}
		{
			transitioningMembers.length > 0 && <>
            <MemberList members={transitioningMembers}
                        subtitle="Continuing Enrollment (New Membership Account)"
                        titleVariant="h5"
                        title="Members"/>
            <Spacer size={40}/>
        </>
		}
		{
			remainingMembers.length > 0 && <>
            <MemberList members={remainingMembers}
                        titleVariant="h5"
                        subtitle="Continuing Enrollment (Current Account)"/>
            <Spacer size={40}/>
        </>
		}
		{withdrawingMembers.length > 0 &&
        <>
            <Typography variant="h5" className={styles.subheading}>Enrollment End Date</Typography>
            <Spacer size={8}/>
            <Typography variant="body1">{moment(enrollmentEndDate).format('MMMM D, YYYY')}</Typography>
            <Spacer size={16}/>
            <MemberList members={withdrawingMembers}
                        subtitle="Withdrawing"
            />
        </>
		}

	</>);
};

export default ConfirmationTab;