import Member from '../../../../../models/member';
import MembershipAccount from '../../../../../models/membershipAccount';
import { DivorceEvent } from '../events/divorce';
import { DialogState } from '../../../../../views/membership/accounts/Account';

export interface DivorceDialogTabProps {
	members: Member[];
	onChange: (members: string[]) => void;
	selectedMembers: string[];
	membershipAccount: MembershipAccount;
}

export interface DivorceDialogProps {
	open: boolean;
	onDialogClose: Function;
	event: DivorceEvent;
	resetDialog: Function;
	members: Member[];
	onUnlinkedMembersChanged: (members: string[]) => void;
	onWithdrawingMembersChanged: (members: string[]) => void;
	withdrawingMembers: string[];
	unlinkedMembers:  string[];
	setDialogsOpen: Function;
	initialDialogState: DialogState;
}

export enum DivorceDialogTabs {
	UNLINK_MEMBERS = 1,
	WITHDRAW_MEMBERS = 2,
	CONFIRMATION = 3
}