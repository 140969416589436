import { Button, makeStyles, Tab, Tabs } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import apiClient from '../../../../../lib/api';
import Member, { Role } from '../../../../../models/member';
import { showToastMessage } from '../../../../../store/slices/toastMessage';
import Dialog from '../../../../Dialog';
import { LoadingButton } from '../../../../LoadingButton';
import { DeathEvent } from '../events/death';
import TabPanel from '../TabPanel';
import { Confirmation } from './Confirmation';
import { DeceasedMemberSelector } from './DeceasedMemberSelector';
import { WithdrawingMemberSelector } from './WithdrawingMemberSelector';
import { DialogState } from '../../../../../views/membership/accounts/Account';

const useStyles = makeStyles(() => ({
  rightAlignedTabs: {
    '& .MuiTab-wrapper': {
      alignItems: 'flex-end',
    },
  },
}));

enum DialogTabs {
  DeceasedMembersTab = 1,
  WithdrawingMembersTab = 2,
  ConfirmationTab = 3,
}

export interface DeathForm {
  dateOfDeath: Date;
  deceasedMemberUids: string[];
  hasWithdrawingMembers: string;
  enrollmentEndDate: Date;
  withdrawingMemberUids: string[];
}

const DeathDialog: React.FC<{
  open: boolean;
  onDialogClose: Function;
  event: DeathEvent;
  resetDialog: Function;
  members: Member[];
  setDialogsOpen: Function;
  initialDialogState: DialogState;
}> = ({ open, onDialogClose, event, resetDialog, members, setDialogsOpen, initialDialogState }) => {
  const styles = useStyles();

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [currentTab, setCurrentTab] = useState<number>(1);


  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);

  const [footer, setFooter] = useState<JSX.Element | null>();
  const [footerMessage, setFooterMessage] = useState<string>('');

  const [hasError, setHasError] = useState<boolean>(false);

  const { membershipAccount } = event;

  const initialEnrollmentEndDate = moment().endOf('month').toDate();

  const form = useFormik<DeathForm>({
    initialValues: {
      dateOfDeath: moment().toDate(),
      deceasedMemberUids: [],
      hasWithdrawingMembers: '',
      enrollmentEndDate: initialEnrollmentEndDate,
      withdrawingMemberUids: [],
    },
    onSubmit: async values => {
      handleConfirm();
    },
  });

  const createDeathRequest = useMutation((values: DeathForm) => {
    const { dateOfDeath, deceasedMemberUids, enrollmentEndDate, withdrawingMemberUids } = values;

    const membershipAccountUId = membershipAccount.uid;

    const payload = {
      membershipAccountUId,
      dateOfDeath: moment(dateOfDeath).format('YYYY-MM-DD'),
      deceasedMembers: deceasedMemberUids,
      enrollmentEndDate: withdrawingMemberUids.length
        ? moment(enrollmentEndDate).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        : null,
      withdrawingMembers: withdrawingMemberUids,
    };

    return apiClient.workflows.startDeathWorkflow(payload);
  });

  const deceasedMembers: Member[] = useMemo(
    () => members.filter(member => form.values.deceasedMemberUids.includes(member.uid)),
    [members, form.values.deceasedMemberUids],
  );

  const survivingMembers: Member[] = useMemo(
    () => members.filter(member => !form.values.deceasedMemberUids.includes(member.uid)),
    [members, form.values.deceasedMemberUids],
  );

  const withdrawingMembers: Member[] = useMemo(() => {
    if (form.values.hasWithdrawingMembers === 'yes') {
      return members.filter(member => form.values.withdrawingMemberUids.includes(member.uid));
    }

    return [];
  }, [form.values.hasWithdrawingMembers, members, form.values.withdrawingMemberUids]);

  const continuingMembers: Member[] = useMemo(
    () =>
      members.filter(
        member =>
          !form.values.deceasedMemberUids.includes(member.uid) &&
          !form.values.withdrawingMemberUids.includes(member.uid),
      ),
    [members, form.values.deceasedMemberUids, form.values.withdrawingMemberUids],
  );

  const accountOwnerUid: string = useMemo(
    () => members.find(member => member.role === Role.AccountOwner)!.uid,
    [members],
  );

  const isAccountOwnerDeceased: boolean = useMemo(
    () => deceasedMembers.some(member => member.uid === accountOwnerUid),
    [deceasedMembers, accountOwnerUid],
  );

  const isAccountOwnerWithdrawing: boolean = useMemo(
    () => withdrawingMembers.some(member => member.uid === accountOwnerUid),
    [withdrawingMembers, accountOwnerUid],
  );

  const spouseUid: string | null = useMemo(
    () => members.find(member => member.role === Role.Admin)?.uid ?? null,
    [members],
  );

  const hasSpouse: boolean = useMemo(() => !!spouseUid, [spouseUid]);

  const isSpouseDeceased: boolean = useMemo(
    () => deceasedMembers.some(member => member.uid === spouseUid),
    [deceasedMembers, spouseUid],
  );
  const isSpouseWithdrawing: boolean = useMemo(
    () => withdrawingMembers.some(member => member.uid === spouseUid),
    [withdrawingMembers, spouseUid],
  );

  const dependentUids: string[] = useMemo(
    () =>
      members
        .filter(member => member.uid !== accountOwnerUid && member.uid !== spouseUid)
        .map(member => member.uid),
    [members, accountOwnerUid, spouseUid],
  );

  const isNewShareAccountRequired: boolean = useMemo(() => {
    return (
      (isAccountOwnerDeceased || isAccountOwnerWithdrawing) &&
      hasSpouse &&
      !isSpouseDeceased &&
      !isSpouseWithdrawing
    );
  }, [
    isAccountOwnerDeceased,
    isAccountOwnerWithdrawing,
    hasSpouse,
    isSpouseDeceased,
    isSpouseWithdrawing,
  ]);

  const previousShouldForceDependentsToWithdraw = useRef<boolean>(false);
  const previousAreBothAdultsDeceased = useRef<boolean>(
    isAccountOwnerDeceased && (!hasSpouse || isSpouseDeceased),
  );
  const shouldForceDependentsToWithdraw: boolean = useMemo(
    () => {
      const hasSurvivingDependents = dependentUids.some(
        uid => !form.values.deceasedMemberUids.includes(uid),
      );

      const areBothAdultsDeceased = isAccountOwnerDeceased && (!hasSpouse || isSpouseDeceased);
      const isOneAdultDeceasedAndOneAdultWithdrawing =
        (isAccountOwnerDeceased && isSpouseWithdrawing) ||
        (isSpouseDeceased && isAccountOwnerWithdrawing);
      const areBothAdultsWithdrawing =
        isAccountOwnerWithdrawing && (!hasSpouse || isSpouseWithdrawing);

      const shouldForce =
        hasSurvivingDependents &&
        (areBothAdultsDeceased ||
          isOneAdultDeceasedAndOneAdultWithdrawing ||
          areBothAdultsWithdrawing);

      if (shouldForce) {
        form.setFieldValue('hasWithdrawingMembers', 'yes');

        form.setFieldValue(
          'withdrawingMemberUids',
          survivingMembers.map(member => member.uid),
        );

        if (
          (currentTab === DialogTabs.DeceasedMembersTab && areBothAdultsDeceased) ||
          currentTab === DialogTabs.WithdrawingMembersTab
        ) {
          setFooterMessage('All remaining members must be withdrawn');
        } else {
          setFooterMessage('');
        }
      } else if (previousShouldForceDependentsToWithdraw.current) {
        if (currentTab === DialogTabs.DeceasedMembersTab && previousAreBothAdultsDeceased.current) {
          form.setFieldValue('hasWithdrawingMembers', '');
        }

        form.setFieldValue('withdrawingMemberUids', []);

        setFooterMessage('');
      }

      previousShouldForceDependentsToWithdraw.current = shouldForce;
      previousAreBothAdultsDeceased.current = areBothAdultsDeceased;

      return shouldForce;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dependentUids,
      form.values.deceasedMemberUids,
      isAccountOwnerDeceased,
      isAccountOwnerWithdrawing,
      hasSpouse,
      isSpouseDeceased,
      isSpouseWithdrawing,
      survivingMembers,
      currentTab,
    ],
  );

  const disabledDependentWithdrawingMemberUids: string[] = useMemo(() => {
    return shouldForceDependentsToWithdraw ? dependentUids : [];
  }, [shouldForceDependentsToWithdraw, dependentUids]);

  const shouldShowWithdrawingMembersQuestion: boolean = useMemo(
    () => {
      const shouldShow = deceasedMembers.length > 0 && survivingMembers.length > 0;

      if (!shouldShow) {
        form.setFieldValue('hasWithdrawingMembers', '');
      }

      return shouldShow;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deceasedMembers, survivingMembers],
  );

  const isNextDisabled: boolean = useMemo(() => {
    let shouldDisable = true;

    if (hasError) {
      shouldDisable = true;
    } else if (currentTab === DialogTabs.DeceasedMembersTab) {
      const hasNoDeceasedMembers = deceasedMembers.length === 0;

      const hasFamily = members.length > 1;
      const hasNotAnsweredWithdrawingMembersQuestion = form.values.hasWithdrawingMembers === '';

      shouldDisable =
        hasNoDeceasedMembers ||
        (hasFamily &&
          shouldShowWithdrawingMembersQuestion &&
          hasNotAnsweredWithdrawingMembersQuestion);
    } else if (currentTab === DialogTabs.WithdrawingMembersTab) {
      const hasNoWithdrawingMembers = withdrawingMembers.length === 0;

      const isAccountOwnerNotContinuing = isAccountOwnerDeceased || isAccountOwnerWithdrawing;
      const isSpouseNotContinuing = isSpouseDeceased || isSpouseWithdrawing;

      const hasContinuingDependents = continuingMembers.some(member =>
        dependentUids.includes(member.uid),
      );

      const hasContinuingDependentsWithoutContinuingAdults =
        isAccountOwnerNotContinuing &&
        (!hasSpouse || isSpouseNotContinuing) &&
        hasContinuingDependents;

      shouldDisable = hasNoWithdrawingMembers || hasContinuingDependentsWithoutContinuingAdults;
    } else if (currentTab === DialogTabs.ConfirmationTab) {
      shouldDisable = false;
    }

    return shouldDisable;
  }, [
    hasError,
    currentTab,
    deceasedMembers,
    members,
    form.values.hasWithdrawingMembers,
    shouldShowWithdrawingMembersQuestion,
    withdrawingMembers,
    continuingMembers,
    dependentUids,
    isAccountOwnerDeceased,
    isAccountOwnerWithdrawing,
    hasSpouse,
    isSpouseDeceased,
    isSpouseWithdrawing,
  ]);

  useEffect(
    function determineIfConfirmIsDisabled() {
      if (hasError) {
        setIsConfirmDisabled(true);
      } else if (currentTab === DialogTabs.DeceasedMembersTab) {
        setIsConfirmDisabled(
          form.values.hasWithdrawingMembers === '' || deceasedMembers.length === 0,
        );
      } else if (currentTab === DialogTabs.WithdrawingMembersTab) {
        setIsConfirmDisabled(withdrawingMembers.length === 0);
      } else if (currentTab === DialogTabs.ConfirmationTab) {
        setIsConfirmDisabled(true);

        setTimeout(() => {
          setIsConfirmDisabled(false);
        }, 1000);
      }
    },
    [
      hasError,
      currentTab,
      form.values.hasWithdrawingMembers,
      deceasedMembers.length,
      withdrawingMembers.length,
    ],
  );

  useEffect(() => {
    if (footerMessage) {
      setFooter(
        <Alert variant="outlined" severity="info">
          {footerMessage}
        </Alert>,
      );
    } else {
      setFooter(null);
    }
  }, [footerMessage]);

  const handleBack = () => {
    if (currentTab === DialogTabs.DeceasedMembersTab) {
      resetDialog();
    }

    if (currentTab === DialogTabs.WithdrawingMembersTab) {
      setCurrentTab(DialogTabs.DeceasedMembersTab);
    }

    if (currentTab === DialogTabs.ConfirmationTab) {
      const shouldSkipWithdrawingMembersTab =
        !shouldShowWithdrawingMembersQuestion || form.values.hasWithdrawingMembers !== 'yes';

      let previousTab = shouldSkipWithdrawingMembersTab
        ? DialogTabs.DeceasedMembersTab
        : DialogTabs.WithdrawingMembersTab;

      setCurrentTab(previousTab);
    }
  };

  const handleNext = () => {
    if (currentTab === DialogTabs.DeceasedMembersTab) {
      if (shouldShowWithdrawingMembersQuestion && form.values.hasWithdrawingMembers === 'yes') {
        setCurrentTab(DialogTabs.WithdrawingMembersTab);
      } else {
        setCurrentTab(DialogTabs.ConfirmationTab);
      }
    } else if (currentTab === DialogTabs.WithdrawingMembersTab) {
      setCurrentTab(DialogTabs.ConfirmationTab);
    }
  };

  const handleConfirm = () => {
    createDeathRequest.mutate(form.values, {
      onSuccess: () => {
        queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);

        dispatch(
          showToastMessage({
            message: `Death workflow started successfully${
              isNewShareAccountRequired ? '. KYC request email was sent to the member.' : ''
            }`,
            type: 'success',
          }),
        );

        handleClose();
        setDialogsOpen({...initialDialogState, manualKycSteps: true});
      },
      onError: (error: any) => {
        dispatch(
          showToastMessage({
            message: `Death workflow failed to start. Contact the Help Desk to resolve the error.${
              error.message ? ` ${error.message}` : ''
            }`,
            type: 'error',
          }),
        );
      },
    });
  };

  const handleClose = () => {
    form.resetForm();
    resetDialog();
    onDialogClose();
  };

  const handleWithdrawingTabClicked = () => {
    if (currentTab === DialogTabs.ConfirmationTab) {
      handleBack();
    }
    if (currentTab === DialogTabs.DeceasedMembersTab) {
      handleNext();
    }
  };

  const resetWithdrawingMembersTab = () => {
    form.setFieldValue('enrollmentEndDate', initialEnrollmentEndDate);
    form.setFieldValue('withdrawingMemberUids', []);
  };

  const renderButtons = () => {
    const nextButton = currentTab !== DialogTabs.ConfirmationTab && (
      <Button
        key="next"
        color="primary"
        variant="contained"
        style={{ marginRight: '15px' }}
        disabled={isNextDisabled}
        hidden={currentTab === DialogTabs.ConfirmationTab}
        onClick={handleNext}
      >
        Next
      </Button>
    );

    const confirmButton = currentTab === DialogTabs.ConfirmationTab && (
      <LoadingButton
        key="confirm"
        color="primary"
        variant="contained"
        style={{ marginRight: '15px' }}
        loading={isConfirmDisabled || createDeathRequest.isLoading}
        onClick={handleConfirm}
      >
        Confirm
      </LoadingButton>
    );

    const cancelButton = (
      <Button
        key="cancel"
        variant="contained"
        onClick={handleClose}
        color="default"
        disabled={createDeathRequest.isLoading}
      >
        Close
      </Button>
    );

    return [nextButton, confirmButton, cancelButton];
  };

  const backButton = (
    <Button key="back" color="default" variant="contained" onClick={handleBack}>
      Back
    </Button>
  );

  return (
    <>
      <Dialog
        open={open}
        title="Life Event: Death"
        maxWidth="xl"
        disableBackdropClick
        onClose={onDialogClose}
        buttons={renderButtons()}
        backButton={backButton}
        footer={footer}
      >
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            height: 670,
            width: 1200,
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            aria-label="Life event tabs"
            value={currentTab}
            indicatorColor="primary"
            style={{ overflow: 'visible' }}
          >
            <Tab
              label="Deceased"
              value={1}
              onClick={() => setCurrentTab(1)}
              className={styles.rightAlignedTabs}
            />
            <Tab
              label="Withdraw"
              value={2}
              disabled={isNextDisabled || !form.values.hasWithdrawingMembers}
              onClick={handleWithdrawingTabClicked}
              className={styles.rightAlignedTabs}
            />
            <Tab
              label="Confirmation"
              value={3}
              disabled={isConfirmDisabled}
              onClick={handleNext}
              className={styles.rightAlignedTabs}
            />
          </Tabs>
          <TabPanel value={currentTab} index={1}>
            <DeceasedMemberSelector
              form={form}
              members={members}
              shouldShowWithdrawingMembersQuestion={shouldShowWithdrawingMembersQuestion}
              shouldDisableWithdrawingMembersQuestion={
                shouldForceDependentsToWithdraw &&
                isAccountOwnerDeceased &&
                (!hasSpouse || isSpouseDeceased)
              }
              membershipAccount={membershipAccount}
              resetWithdrawingMembersTab={resetWithdrawingMembersTab}
              isInvalid={setHasError}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <WithdrawingMemberSelector
              form={form}
              survivingMembers={survivingMembers}
              withdrawingMembers={withdrawingMembers}
              disabledWithdrawingMemberUids={disabledDependentWithdrawingMemberUids}
              hasNoSurvivingAdultMembers={
                isAccountOwnerDeceased && (!hasSpouse || isSpouseDeceased)
              }
              membershipAccount={membershipAccount}
              isInvalid={setHasError}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <Confirmation
              form={form}
              deceasedMembers={deceasedMembers}
              withdrawingMembers={withdrawingMembers}
              continuingMembers={continuingMembers}
                isNewShareAccountRequired={isNewShareAccountRequired}
            />
          </TabPanel>
        </div>
      </Dialog>
    </>
  );
};

export default DeathDialog;
