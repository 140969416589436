import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import Member from '../../../models/member';
import MembershipAccount from '../../../models/membershipAccount';
import MemberCard from './MemberCard';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		membersContainer: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			gap: '16px',
		},
		header: {
			display: 'flex',
			justifyContent: 'start',
			alignItems: 'center',
			marginBottom: '10px',
			gap: '5px',
			'& Button': {
				fontSize: '12px',
				textDecorationLine: 'underline',
				fontWeight: 500,
				lineHeight: '18px',
				textTransform: 'none',
			},
		},
		title: {
			fontSize: '18px',
			letterSpacing: 0.15,
			margin: 0,
			fontWeight: 400,
			lineHeight: '21px',
			textTransform: 'none',
		},
	}),
);

interface MemberSelectorProps {
	title?: string;
	titleVariant?: Variant;
	members: Member[];
	selectedMemberUids: string[];
	disabledMemberUids?: string[];
	shouldDisableSelectAll?: boolean;
	membershipAccount: MembershipAccount;
	onChange: (selectedMemberUids: string[]) => void;
}

export const MemberSelector: React.FC<MemberSelectorProps> = ({
																																title = 'Members',
																																titleVariant = 'h6',
																																members,
																																selectedMemberUids,
																																disabledMemberUids = [],
																																shouldDisableSelectAll = false,
																																membershipAccount,
																																onChange,
																															}) => {
	const styles = useStyles();
	const [selectableMembers, setSelectableMembers] = useState<Member[]>([]);

	useEffect(() => {
		setSelectableMembers(members.filter(member => member.readonly === undefined || !member.readonly))
	}, [members]);

	const allSelected = selectableMembers.length === selectedMemberUids.length;

	const handleSelectAll = () => {
		if (allSelected) {
			// Deselect all members
			onChange([]);
		} else {
			// Select all members
			onChange(selectableMembers.map(member => member.uid));
		}
	};

	const handleMemberSelection = (memberUid: string) => {
		let updatedSelection;

		if (selectedMemberUids.includes(memberUid)) {
			updatedSelection = selectedMemberUids.filter(uid => uid !== memberUid);
		} else {
			updatedSelection = [...selectedMemberUids, memberUid];
		}

		onChange(updatedSelection);
	};

	return members.length > 0 ? (
		<>
			<Box className={styles.header}>
				<Typography variant={titleVariant} className={titleVariant ? undefined : styles.title}>
					{title}
				</Typography>
				{members.length > 1 && (
					<Button
						onClick={handleSelectAll}
						variant="text"
						color="primary"
						disabled={shouldDisableSelectAll}
						style={{fontWeight: 600}}
					>
						{allSelected ? 'Deselect All' : 'Select All'}
					</Button>
				)}
			</Box>
			<Box className={styles.membersContainer}>
				{members.map((member: Member, index: number) => (
					<MemberCard
						key={index}
						member={member}
						selectable={member.readonly === undefined}
						selected={selectedMemberUids.includes(member.uid)}
						onMemberSelected={() => handleMemberSelection(member.uid)}
						disabled={disabledMemberUids && disabledMemberUids.includes(member.uid)}
						programEnrollments={
							membershipAccount.getProgramEnrollmentsByMemberUid(member.uid) || []
						}
					/>
				))}
			</Box>
		</>
	) : (
		<Alert severity="warning">There are no members to select from</Alert>
	);
};