import DateFnsUtils from '@date-io/date-fns';
import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import Member from '../../../../../models/member';
import MembershipAccount from '../../../../../models/membershipAccount';
import Spacer from '../../../../Spacer';
import { MemberSelector } from '../../../member';
import { DeathForm } from './DeathDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioButtonGroupLabel: {
      '& label': { color: theme.textColors.default },
      ' & .Mui-focused': {
        color: theme.textColors.default,
      },
    },
  }),
);

export const DeceasedMemberSelector: React.FC<{
  form: FormikProps<DeathForm>;
  members: Member[];
  shouldShowWithdrawingMembersQuestion: boolean;
  shouldDisableWithdrawingMembersQuestion: boolean;
  membershipAccount: MembershipAccount;
  resetWithdrawingMembersTab: () => void;
  isInvalid: (hasError: boolean) => void;
}> = ({
  form,
  members,
  shouldShowWithdrawingMembersQuestion,
  shouldDisableWithdrawingMembersQuestion,
  membershipAccount,
  resetWithdrawingMembersTab,
  isInvalid,
}) => {
  const styles = useStyles();

  const handleDeceasedMembersSelectionChanged = (selectedDeceasedMemberUids: string[]) => {
    form.setFieldValue('deceasedMemberUids', selectedDeceasedMemberUids);

    resetWithdrawingMembersTab();
  };

  const handleWithdrawingMembersQuestionOptionChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    form.setFieldValue('hasWithdrawingMembers', event.target.value);

    resetWithdrawingMembersTab();
  };

  return (
    <>
      <Typography variant="h4">Deceased Members</Typography>
      <Spacer size={8} />
      <Typography variant="body2">Please select all that apply</Typography>
      <Spacer size={32} />
      <Typography variant="h5">Date of Death</Typography>
      <Spacer size={16} />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          label="Date of Death"
          format="MM/dd/yyyy"
          inputVariant="outlined"
          disableToolbar
          value={form.values.dateOfDeath}
          variant="inline"
          onChange={date => form.setFieldValue('dateOfDeath', date)}
          minDate={moment().subtract(12, 'month')}
          maxDate={moment()}
          minDateMessage={`The date of death cannot be more than 12 months in the past`}
          maxDateMessage={`The date of death cannot be in the future`}
          onError={error => isInvalid(!!error)}
        />
      </MuiPickersUtilsProvider>
      <Spacer size={40} />
      <MemberSelector
        title="Members"
        titleVariant="h5"
        members={members}
        selectedMemberUids={form.values.deceasedMemberUids}
        membershipAccount={membershipAccount}
        onChange={handleDeceasedMembersSelectionChanged}
      />
      <Spacer size={48} />
      {shouldShowWithdrawingMembersQuestion && (
        <FormControl variant="outlined" fullWidth className={styles.radioButtonGroupLabel}>
          <FormLabel id="withdrawing-members-question">
            Will any of the surviving members be withdrawing?
          </FormLabel>
          <Spacer size={12} />
          <RadioGroup
            aria-labelledby="withdrawing-members-question"
            name="withdrawingMembers"
            value={form.values.hasWithdrawingMembers}
            onChange={handleWithdrawingMembersQuestionOptionChanged}
          >
            <FormControlLabel
              disabled={shouldDisableWithdrawingMembersQuestion}
              value="yes"
              control={<Radio color="primary" />}
              label="Yes"
            />
            <FormControlLabel
              disabled={shouldDisableWithdrawingMembersQuestion}
              value="no"
              control={<Radio color="primary" />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      )}
    </>
  );
};
