import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateSessionActivity } from '../store/slices/session';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    backButtonWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);

const CustomDialog = ({ title, buttons, backButton, footer, children, ...rest }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const scroll = rest.scroll || 'paper';
  const maxWidth = rest.maxWidth || false;

  useEffect(() => {
    if (!rest.preventUserActivity) {
      dispatch(updateSessionActivity());
    }
  }, [dispatch, rest.open, rest.preventUserActivity]);

  return (
    <Dialog scroll={scroll} maxWidth={maxWidth} {...rest}>
      {title && (
        <DialogTitle disableTypography>
          <Typography variant="h3" style={{ margin: 0 }}>
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <footer
        style={
          rest.footerStyle || { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }
        }
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={2} className={classes.backButtonWrapper}>
              {backButton && (
                <DialogActions style={{ padding: '24px' }}>{backButton}</DialogActions>
              )}
            </Grid>
            <Grid item xs={10} className={classes.buttonWrapper}>
              <div style={{ display: 'flex', alignItems: 'center', paddingRight: 20 }}>
                {footer}
              </div>
              {buttons && <DialogActions style={{ padding: '24px' }}>{buttons}</DialogActions>}
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </Dialog>
  );
};

export default CustomDialog;
