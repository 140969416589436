import Dialog from '../../../Dialog';
import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Spacer from '../../../Spacer';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& footer': {
        justifyContent: 'center',
      }
    },
    wrapper: {
      textAlign: 'center',
      height: 400,
      width: 600,
    }
  }),
);

const ManualKycStepsRequiredDialog: React.FC<{ onDialogClose: Function, open: boolean }> = ({
                                                                                              onDialogClose,
                                                                                              open
                                                                                            }) => {

  const styles = useStyles();

  const renderButtons = () => {
    const closeButton = (
      <Button
        key="close"
        color="default"
        variant="contained"
        style={{ marginRight: '15px', justifySelf: 'flex-center' }}
        onClick={() => onDialogClose()}
      >
        Close
      </Button>
    );

    return [closeButton];
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={onDialogClose}
      buttons={renderButtons()}
      footerStyle = {{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
    >
      <div
        className={styles.wrapper}
      >
        <Spacer size={50}/>
        <ErrorOutlineIcon color="primary" style={{ width: 100, height: 100 }}/>
        <Typography variant="h2">Membership Update Required</Typography>
        <Spacer size={10}/>
        <Typography variant="body1">Please begin manual workflow process as next step</Typography>
      </div>
    </Dialog>
  );

}


export default ManualKycStepsRequiredDialog;
