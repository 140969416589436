import { Alert, AlertTitle } from '@material-ui/lab';
import { FormikProps } from 'formik';
import React from 'react';
import Member from '../../../../../models/member';
import Spacer from '../../../../Spacer';
import { HeadOfHouseholdChangeForm } from './HeadOfHouseholdChangeDialog';
import { MemberList } from '../../../member/MemberList';

export const Confirmation: React.FC<{
  members: Member[];
  form: FormikProps<HeadOfHouseholdChangeForm>;
}> = ({ members, form }) => (
  <>
    {
      <Alert severity="warning" variant="outlined">
        <AlertTitle>New Share Account Required</AlertTitle>
        Continue to request KYC check for the eligible spouse.
      </Alert>
    }

    {form.values.event?.newAccountOwner && (
      <section>
        <Spacer size={36} />
        <MemberList
          title="Eligible Spouse"
          titleVariant="h5"
          members={members.filter(member => form.values.event!.newAccountOwner === member.uid)}
        />
      </section>
    )}

    {form.values.event?.previousAccountOwner && (
      <section>
        <Spacer size={36} />
        <MemberList
          title="Current Account Owner"
          titleVariant="h5"
          members={members.filter(member => form.values.event!.previousAccountOwner === member.uid)}
        />
      </section>
    )}
  </>
);
