export enum LifeEventName {
  Death = 'Death',
  HeadOfHouseholdChange = 'Head of Household Change',
  Divorce = 'Divorce',
}
export interface LifeEventOption {
  value: LifeEventName;
}

export const lifeEventOptions: LifeEventOption[] = [
  { value: LifeEventName.Death },
  { value: LifeEventName.HeadOfHouseholdChange },
  { value: LifeEventName.Divorce },
];
