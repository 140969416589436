import React from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import Spacer from '../../../../Spacer';
import { MemberSelector } from '../../../member';
import { DivorceDialogTabProps } from './constants';

interface MemberUnlinkTabProps extends DivorceDialogTabProps {
	hasSelectedUnlinkedMembers: (members: string[]) => boolean;
	hasWithdrawingMembers: string;
	onOptionChanged: (value: string) => void;
}

const MemberUnlinkTab: React.FC<MemberUnlinkTabProps> = ({
																													 members,
																													 selectedMembers,
																													 membershipAccount,
																													 onChange,
																													 onOptionChanged,
																													 hasWithdrawingMembers,
																												 }) => {
	const handleMembersWithdrawingOptionChanged = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const {value} = event.target;
		onOptionChanged(value);
	};

	return (<>
		<Typography variant="h4">Unlink from Current Account</Typography>
		<Spacer size={8}/>
		<Typography variant="body2">Select all that apply</Typography>
		<Spacer size={32}/>
		<MemberSelector
			titleVariant="h5"
			members={members}
			selectedMemberUids={selectedMembers}
			onChange={onChange}
			membershipAccount={membershipAccount}/>
		<Spacer size={36}/>
		{selectedMembers.length > 0 &&
        <FormControl variant="outlined" fullWidth>
            <FormLabel>
                <Typography variant="body1">Will any of the above members be withdrawing?</Typography>
            </FormLabel>
						<Spacer size={12}/>
            <RadioGroup
                name="membersWithdrawing"
                value={hasWithdrawingMembers}
                onChange={handleMembersWithdrawingOptionChanged}
            >
                <FormControlLabel value="yes" control={<Radio color="primary"/>} label="Yes"/>
                <FormControlLabel value="no" control={<Radio color="primary"/>} label="No"/>
            </RadioGroup>
        </FormControl>
		}
	</>);
};

export default MemberUnlinkTab;