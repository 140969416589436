import Organization from './organization';
import Group from './group';
import MembershipAccount from './membershipAccount';
import Person from './person';
import ProgramEnrollment from './programEnrollment';

export default interface Member {
  uid: string;
  organization: Organization | string;
  group: Group | string;
  membershipAccount: MembershipAccount | string;
  person: Person | string;
  programEnrollments?: ProgramEnrollment[];
  memberNumber: string;
  sfContactId?: string;
  sfContactUID?: string;
  netsuiteId?: string;
  ssn?: string;
  role: string;
  dependentNumber: string;
  retired?: boolean;
  settings: object;
  status: string;
  ended: string | null;
  archived?: boolean;
  readonly?: boolean;
}

export interface TransitioningMember {
  contactUID?: string;
  startDate: string | null;
  endDate: string | null;
  memberUId: string;
  retired?: boolean;
  role: string;
  status: string;
}

export enum AlloyJourneyStatus {
  // Pending
  RUNNING = 'running',
  COMPLETED = 'completed',
  // - Member Action Required
  DATA_REQUEST = 'data_request',
  PENDING_ACTION = 'pending_action',
  PENDING_ADDITIONAL_ENTITIES = 'pending_additional_entities',
  PENDING_DOCUMENTS = 'pending_documents',
  PENDING_RECONCILIATION = 'pending_reconciliation',
  PENDING_STEP_UP = 'pending_step_up',
  PENDING_WORKFLOW_SERVICE = 'pending_workflow_service',
  // - Financial Institution Action Required
  WAITING_REVIEW = 'waiting_review',
  PENDING_JOURNEY_APPLICATION_REVIEW = 'pending_journey_application_review',

  // Failed
  ERROR = 'error',
  EXCEPTION = 'exception',

  // Expired
  EXPIRED = 'expired',

  // Approved
  APPROVED = 'approved',

  // Denied
  DENIED = 'denied',
}

export enum Role {
  AccountOwner = 'account_owner',
  Admin = 'admin',
  Member = 'member',
}

export enum MemberStatus {
  Pending = 'pending',
  Active = 'active',
}
