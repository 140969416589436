import WorkflowInstance from '../../models/workflowInstance';
import { APIClientBase } from './apiClientBase';
import apiClient from './client';

export interface StartDeathWorkflowPayload {
  membershipAccountUId: string;
  dateOfDeath: string;
  deceasedMembers: string[];
  enrollmentEndDate?: string | null;
  withdrawingMembers: string[];
}

export interface StartHeadOfHouseholdChangeWorkflowPayload {
  membershipAccountUId: string;
  newAccountOwner: string;
  previousAccountOwner: string;
}

export interface StartDivorceWorkflowPayload {
  membershipAccountUId: string;
  enrollmentEndDate: string;
  transitioningMembers: string[];
  withdrawingMembers: string[];
}

class Workflows extends APIClientBase {
  private static _instance: Workflows;

  static getInstance(): Workflows {
    if (Workflows._instance === undefined) {
      Workflows._instance = new Workflows();
    }
    return Workflows._instance;
  }

  startDeathWorkflow = async (payload: StartDeathWorkflowPayload): Promise<WorkflowInstance> => {
    try {
      const url = `/membership/workflows/death`;
      const response = await apiClient.post(url, payload);
      return response.data;
    } catch (err) {
      throw this.error(err);
    }
  };

  startHeadOfHouseholdChangeWorkflow = async (
    payload: StartHeadOfHouseholdChangeWorkflowPayload,
  ): Promise<WorkflowInstance> => {
    try {
      const url = `/membership/workflows/head-of-household-change`;
      const response = await apiClient.post(url, payload);
      return response.data;
    } catch (err) {
      throw this.error(err);
    }
  };

  startDivorceWorkflow = async (payload: StartDivorceWorkflowPayload): Promise<WorkflowInstance> => {
    try {
      const url = `/membership/workflows/divorce`;
      const response = await apiClient.post(url, payload);
      return response.data;
    } catch (err) {
      throw this.error(err);
    }
  };
}

export default Workflows.getInstance();
