import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export const LoadingButton = ({ loading, children, ...rest }: any) => {
  return (
    <Button disabled={loading} {...rest}>
      {children}
      {loading ? (
        <div
          style={{
            position: 'absolute',
            top: '15%',
          }}
        >
          <CircularProgress size="1.5rem" />
        </div>
      ) : null}
    </Button>
  );
};
