import { Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import React from 'react';
import Member from '../../../../../models/member';
import Spacer from '../../../../Spacer';
import { HeadOfHouseholdChangeForm } from './HeadOfHouseholdChangeDialog';
import MembershipAccount from '../../../../../models/membershipAccount';
import { MemberSelector } from '../../../member';
import { MemberList } from '../../../member/MemberList';
import { Alert } from '@material-ui/lab';

export const HeadOfHouseholdSelector: React.FC<{
  members: Member[];
  membershipAccount: MembershipAccount;
  form: FormikProps<HeadOfHouseholdChangeForm>;
}> = ({ members, form, membershipAccount }) => {
  const newAccountOwners = members.filter(
    member => form.values.event!.newAccountOwner === member.uid,
  );

  let AccountOwnerMemberErrorInformation: JSX.Element | undefined;
  if (!form.values.event!.previousAccountOwner) {
    AccountOwnerMemberErrorInformation = <Alert severity="warning">No account owner.</Alert>;
  }

  let SpouseMember: JSX.Element | undefined;
  if (!form.values.event!.newAccountOwner) {
    SpouseMember = (
      <Alert severity="warning">
        No eligible spouses found. An eligible spouse must be an adult with an active or pending
        enrollment, and not already the account owner.
      </Alert>
    );
  } else {
    SpouseMember = (
      <MemberSelector
        shouldDisableSelectAll={true}
        members={newAccountOwners}
        selectedMemberUids={newAccountOwners.map(member => member.uid)}
        disabledMemberUids={newAccountOwners.map(member => member.uid)}
        membershipAccount={membershipAccount}
        onChange={() => {}}
        title="Eligible Spouse"
        titleVariant="h5"
      />
    );
  }
  return (
    <>
      <Typography variant="h3">Head of Household</Typography>
      <Typography variant="body2">Please continue to confirm changes</Typography>
      <Spacer size={36} />
      {SpouseMember}
      <Spacer size={36} />
      <MemberList
        title="Current Account Owner"
        titleVariant="h5"
        members={members.filter(member => form.values.event!.previousAccountOwner === member.uid)}
      />
      {AccountOwnerMemberErrorInformation}
    </>
  );
};
