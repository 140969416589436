import { BaseEvent } from './base';
import { LifeEventName } from './constants';
import MembershipAccount from '../../../../../models/membershipAccount';
import Member, { Role } from '../../../../../models/member';
import moment from 'moment';
import Person from '../../../../../models/person';

export class HeadOfHouseholdChangeEvent extends BaseEvent {
  newAccountOwner: string | null;

  previousAccountOwner: string | null;

  constructor(membershipAccount: MembershipAccount, members: Member[]) {
    super(LifeEventName.HeadOfHouseholdChange, membershipAccount);
    this.newAccountOwner = null;
    this.previousAccountOwner = null;
    members.forEach(member => {
      switch (member.role) {
        case Role.AccountOwner:
          this.previousAccountOwner = member.uid;
          break;
        case Role.Admin:
          // eslint-disable-next-line no-case-declarations
          const isAdult = moment((member.person as Person).dob).isSameOrBefore(
            moment().tz('America/New_York').subtract(18, 'years'),
          );
          if (isAdult) {
            this.newAccountOwner = member.uid;
          }
          break;
        default:
          break;
      }
    });
  }
}
