import {
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Member from '../../../../models/member';
import MembershipAccount from '../../../../models/membershipAccount';
import Dialog from '../../../Dialog';
import DeathDialog from './death/DeathDialog';
import { LifeEventName, LifeEventOption, lifeEventOptions } from './events/constants';
import { DeathEvent } from './events/death';
import HeadOfHouseholdChangeDialog from './head-of-household/HeadOfHouseholdChangeDialog';
import { HeadOfHouseholdChangeEvent } from './events/head-of-household-change';
import DivorceDialog from './divorce/DivorceDialog';
import { DivorceEvent } from './events/divorce';
import { DialogState } from '../../../../views/membership/accounts/Account';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioButtonGroupLabel: {
      '& label': { color: theme.textColors.default },
      ' & .Mui-focused': {
        color: theme.textColors.default,
      },
    },
  }),
);

interface LifeEventProps {
  membershipAccount: MembershipAccount;
  onDialogClose: Function;
  open: boolean;
  setDialogsOpen: Function;
  initialDialogState: DialogState;
}

type LifeEventType = DeathEvent | DivorceEvent | HeadOfHouseholdChangeEvent;


const LifeEvents: React.FC<LifeEventProps> = ({ membershipAccount, onDialogClose, open, setDialogsOpen, initialDialogState }) => {
  const styles = useStyles();
  const [selectedLifeEvent, setSelectedLifeEvent] = useState<LifeEventType | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [account, setAccount] = useState<MembershipAccount | null>(null);
  const [divorceWithdrawingMembers, setDivorceWithdrawingMembers] = useState<string[]>([]);
  const [divorceUnlinkedMembers, setDivorceUnlinkedMembers] = useState<string[]>([]);

  useEffect(() => {
    // Gets member program enrollments
    const members = membershipAccount.members?.map((member: Member) => {
      const programEnrollments =
        membershipAccount.getProgramEnrollmentsByMemberUid(member.uid) || [];
      return { ...member, programEnrollments };
    });

    setAccount({ ...membershipAccount, members });
  }, [membershipAccount]);

  const availableMembers = useMemo<Member[]>(() => {
    return (
      account?.members?.filter(
        member =>
          member.retired ||
          member.programEnrollments?.some(enrollment => {
            return (
              (enrollment.ended && moment(enrollment.ended).isAfter(new Date())) ||
              enrollment.ended === null ||
              (enrollment.started && moment(enrollment.started).isAfter(new Date()))
            );
          }),
      ) || []
    );
  }, [account?.members]);

  const handleNextClicked = () => {
    if (selectedOption === LifeEventName.Death) {
      setSelectedLifeEvent(new DeathEvent(account!));
    }
    if (selectedOption === LifeEventName.HeadOfHouseholdChange) {
      setSelectedLifeEvent(new HeadOfHouseholdChangeEvent(account!, availableMembers));
    }
    if (selectedOption === LifeEventName.Divorce) {
      setSelectedLifeEvent(new DivorceEvent(account!));
    }
  };

  const resetDialog = () => {
    setSelectedLifeEvent(null);
    setSelectedOption(null);
    setDivorceWithdrawingMembers([]);
    setDivorceUnlinkedMembers([]);
  };

  const handleSelectedOptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = event.target.value;
    setSelectedOption(selectedOption);
  };

  const handleCancelClicked = () => {
    setSelectedLifeEvent(null);
    setSelectedOption(null);
    resetDialog();
    onDialogClose();
  };

  const onDivorceUnlinkedMembersChanged = (members: string[]): void =>  {
    setDivorceUnlinkedMembers(members);
  }

  const onDivorceWithdrawingMembersChanged = (members: string[]): void =>  {
    setDivorceWithdrawingMembers(members);
  }

  const renderButtons = () => {
    const nextButton = (
      <Button
        key="next"
        color="primary"
        variant="contained"
        style={{ marginRight: '15px' }}
        onClick={handleNextClicked}
        disabled={!selectedOption}
      >
        Continue
      </Button>
    );

    const cancelButton = (
      <Button key="cancel" variant="contained" onClick={handleCancelClicked} color="default">
        Close
      </Button>
    );

    return [nextButton, cancelButton];
  };

  return (
    <>
      {!selectedLifeEvent && (
        <Dialog
          open={open}
          title="Life Event"
          disableBackdropClick
          maxWidth="xl"
          onClose={onDialogClose}
          buttons={renderButtons()}
        >
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              height: 670,
              width: 1200,
            }}
          >
            <form>
              <FormControl variant="outlined" fullWidth className={styles.radioButtonGroupLabel}>
                <FormLabel id="life-event-radio-buttons-label">
                  Please select a life event
                </FormLabel>
                <RadioGroup
                  aria-labelledby="life-event-radio-buttons-label"
                  name="selectedLifeEventUId"
                  value={selectedOption}
                  onChange={handleSelectedOptionChanged}
                >
                  {lifeEventOptions.map((option: LifeEventOption) => (
                    <FormControlLabel
                      value={option.value}
                      key={option.value}
                      control={<Radio color="primary" />}
                      label={option.value}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </form>
          </div>
        </Dialog>
      )}
      {selectedLifeEvent && selectedLifeEvent.name === LifeEventName.Death && (
        <DeathDialog
          open={open}
          onDialogClose={onDialogClose}
          resetDialog={resetDialog}
          event={selectedLifeEvent as DeathEvent}
          members={availableMembers}
          setDialogsOpen={setDialogsOpen}
          initialDialogState={initialDialogState}
        />
      )}
      {selectedLifeEvent && selectedLifeEvent.name === LifeEventName.HeadOfHouseholdChange && (
        <HeadOfHouseholdChangeDialog
          open={open}
          onDialogClose={onDialogClose}
          resetDialog={resetDialog}
          event={selectedLifeEvent as HeadOfHouseholdChangeEvent}
          members={availableMembers}
          setDialogsOpen={setDialogsOpen}
          initialDialogState={initialDialogState}
        />
      )}
      {selectedLifeEvent && selectedLifeEvent.name === LifeEventName.Divorce && (
        <DivorceDialog
          open={open}
          onDialogClose={onDialogClose}
          resetDialog={resetDialog}
          event={selectedLifeEvent}
          members={availableMembers}
          withdrawingMembers={divorceWithdrawingMembers}
          unlinkedMembers={divorceUnlinkedMembers}
          onWithdrawingMembersChanged={onDivorceWithdrawingMembersChanged}
          onUnlinkedMembersChanged={onDivorceUnlinkedMembersChanged}
          setDialogsOpen={setDialogsOpen}
          initialDialogState={initialDialogState}
        />
      )}
    </>
  );
};

export default LifeEvents;
