import { Box, Button, Divider, Tab, Tabs } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import apiClient from '../../../../../lib/api';
import Member from '../../../../../models/member';
import { showToastMessage } from '../../../../../store/slices/toastMessage';
import Dialog from '../../../../Dialog';
import { HeadOfHouseholdChangeEvent } from '../events/head-of-household-change';
import TabPanel from '../TabPanel';
import { Confirmation } from './Confirmation';
import { StartHeadOfHouseholdChangeWorkflowPayload } from '../../../../../lib/api/workflows';
import { HeadOfHouseholdSelector } from './HeadOfHouseholdSelector';
import { Alert } from '@material-ui/lab';
import { LoadingButton } from '../../../../LoadingButton';
import { DialogState } from '../../../../../views/membership/accounts/Account';

enum DialogTabs {
  HeadOfHouseholdChangeTab = 1,
  ConfirmationTab = 2,
}

export interface HeadOfHouseholdChangeForm {
  event: HeadOfHouseholdChangeEvent | null;
  newAccountOwner: string | null;
  previousAccountOwner: string | null;
}

const HeadOfHouseholdChangeDialog: React.FC<{
  open: boolean;
  onDialogClose: Function;
  event: HeadOfHouseholdChangeEvent;
  resetDialog: Function;
  members: Member[];
  setDialogsOpen: Function;
  initialDialogState: DialogState;
}> = ({ open, onDialogClose, event, resetDialog, members, setDialogsOpen, initialDialogState }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState<number>(1);
  const { membershipAccount } = event;
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);
  const [footer, setFooter] = useState<JSX.Element | null>();

  useEffect(() => {
    if (currentTab === DialogTabs.ConfirmationTab) {
      setIsConfirmDisabled(true);

      setTimeout(() => {
        setIsConfirmDisabled(false);
      }, 1000);
    }
  }, [currentTab]);

  const form = useFormik<HeadOfHouseholdChangeForm>({
    initialValues: {
      event,
      newAccountOwner: '',
      previousAccountOwner: '',
    },
    onSubmit: async values => {
      handleConfirm();
    },
  });

  const createHeadOfHouseholdChangeRequest = useMutation((values: HeadOfHouseholdChangeForm) => {
    const headOfHouseholdChangeEvent = values.event;
    const { newAccountOwner, previousAccountOwner } = headOfHouseholdChangeEvent!;
    const membershipAccountUId = headOfHouseholdChangeEvent!.membershipAccount.uid;

    if (!newAccountOwner || !previousAccountOwner) {
      throw new Error('New and previous account owners were not set!');
    }

    const payload: StartHeadOfHouseholdChangeWorkflowPayload = {
      membershipAccountUId,
      newAccountOwner,
      previousAccountOwner,
    };

    return apiClient.workflows.startHeadOfHouseholdChangeWorkflow(payload);
  });

  const handleConfirm = () => {
    createHeadOfHouseholdChangeRequest.mutate(form.values, {
      onSuccess: () => {
        queryClient.invalidateQueries(['membershipAccount', membershipAccount.uid]);
        dispatch(
          showToastMessage({
            message: 'KYC Request sent',
            type: 'success',
          }),
        );
        handleCloseDialog();
        setDialogsOpen({...initialDialogState, manualKycSteps: true});
      },
      onError: (error: any) => {
        dispatch(
          showToastMessage({
            message: `Error submitting KYC request ${error.message ? `. ${error.message}` : ''}`,
            type: 'error',
          }),
        );
      },
    });
  };

  const handleNextClicked = () => {
    if (currentTab === DialogTabs.HeadOfHouseholdChangeTab) {
      traverseToConfirmationTab();
    }
  };

  const traverseToConfirmationTab = () => {
    setCurrentTab(DialogTabs.ConfirmationTab);
    isConfirmationDisabled();
  };

  const handleCloseDialog = () => {
    form.resetForm();
    resetDialog();
    onDialogClose();
  };

  const handleBackClicked = () => {
    if (currentTab === DialogTabs.HeadOfHouseholdChangeTab) {
      resetDialog();
    }
    if (currentTab === DialogTabs.ConfirmationTab) {
      setCurrentTab(DialogTabs.HeadOfHouseholdChangeTab);
    }
    isConfirmationDisabled();
  };

  const isNextDisabled = (): boolean => {
    if (currentTab === DialogTabs.HeadOfHouseholdChangeTab) {
      return (
        form.values.event === null ||
        !(form.values.event.newAccountOwner && form.values.event.previousAccountOwner)
      );
    }
    return false;
  };

  const renderButtons = () => {
    const nextButton = currentTab !== DialogTabs.ConfirmationTab && (
      <Button
        key="next"
        color="primary"
        variant="contained"
        style={{ marginRight: '15px' }}
        disabled={isNextDisabled()}
        hidden={currentTab === DialogTabs.ConfirmationTab}
        onClick={handleNextClicked}
      >
        Continue
      </Button>
    );

    const confirmButton = currentTab === DialogTabs.ConfirmationTab && (
      <LoadingButton
        key="confirm"
        color="primary"
        variant="contained"
        style={{ marginRight: '15px' }}
        loading={isConfirmDisabled || createHeadOfHouseholdChangeRequest.isLoading}
        onClick={handleConfirm}
      >
        Confirm
      </LoadingButton>
    );

    const cancelButton = (
      <Button
        key="cancel"
        variant="contained"
        onClick={handleCloseDialog}
        color="default"
        disabled={createHeadOfHouseholdChangeRequest.isLoading}
      >
        Close
      </Button>
    );
    return [nextButton, confirmButton, cancelButton];
  };

  const isConfirmationDisabled = () => {
    if (currentTab === DialogTabs.HeadOfHouseholdChangeTab) {
      setIsConfirmDisabled(
        !(form.values.event!.previousAccountOwner && form.values.newAccountOwner),
      );
    }
  };

  useEffect(() => {
    const memberTypes = [];
    if (!form.values.event!.previousAccountOwner) {
      memberTypes.push('previous');
    }
    if (!form.values.event!.newAccountOwner) {
      memberTypes.push('new');
    }
    if (memberTypes.length > 0) {
      setFooter(
        <Alert variant="outlined" severity="info">
          There must be a {memberTypes.join(' and ')} account owner.
        </Alert>,
      );
    } else {
      setFooter(null);
    }
  }, [footer, form.values.event]);

  const backButton = (
    <Button key="back" color="default" variant="contained" onClick={handleBackClicked}>
      Back
    </Button>
  );

  return (
    <>
      <Dialog
        open={open}
        title={'Life Event: Head of Household Change'}
        maxWidth="xl"
        disableBackdropClick
        onClose={onDialogClose}
        buttons={renderButtons()}
        backButton={backButton}
        footer={footer}
      >
        <Box display="flex" height={700} width={1200} py={2}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            aria-label="Life event tabs"
            value={currentTab}
            indicatorColor="primary"
            style={{ overflow: 'visible' }}
          >
            <Tab
              label={
                <Box textAlign="right" width="100%">
                  Head of
                  <br />
                  Household
                  <br />
                  Change
                </Box>
              }
              value={1}
              disabled={isNextDisabled()}
              onClick={() => setCurrentTab(1)}
            />
            <Tab
              label={
                <Box textAlign="right" width="100%">
                  Confirmation
                </Box>
              }
              value={2}
              disabled={isConfirmDisabled}
              onClick={() => handleNextClicked()}
            />
          </Tabs>
          <Divider orientation="vertical" flexItem />

          <Box width="100%" px={5}>
            <TabPanel value={currentTab} index={1}>
              <HeadOfHouseholdSelector
                members={members}
                membershipAccount={membershipAccount}
                form={form}
              />
            </TabPanel>
            <Box width="100%" display="flex" flexDirection="column">
              <TabPanel value={currentTab} index={2}>
                <Confirmation members={members} form={form} />
              </TabPanel>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default HeadOfHouseholdChangeDialog;
