import { Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import Member from '../../../../../models/member';
import Spacer from '../../../../Spacer';
import { MemberList } from '../../../member/MemberList';
import { DeathForm } from './DeathDialog';

export const Confirmation: React.FC<{
  form: FormikProps<DeathForm>;
  deceasedMembers: Member[];
  withdrawingMembers: Member[];
  continuingMembers: Member[];
  isNewShareAccountRequired: boolean;
}> = ({
  form,
  deceasedMembers,
  withdrawingMembers,
  continuingMembers,
  isNewShareAccountRequired,
}) => {
  return (
    <>
      {isNewShareAccountRequired && (
        <Alert severity="warning" variant="outlined">
          <AlertTitle>New Share Account Required</AlertTitle>
          Continue to request KYC check for surviving spouse.
        </Alert>
      )}
      {!isNewShareAccountRequired && (
        <Alert severity="warning" variant="outlined">
          <AlertTitle>New Share Account Not Required</AlertTitle>
          No KYC Required.
        </Alert>
      )}

      {continuingMembers.length > 0 && (
        <section>
          <Spacer size={32} />
          <Typography variant="h5">Members</Typography>
          <Spacer size={20} />
          <MemberList
            title={`Continuing Enrollment (${
              isNewShareAccountRequired ? 'New Membership Account' : 'Current Account'
            })`}
            titleVariant="h6"
            members={continuingMembers}
          />
        </section>
      )}

      <section>
        <Spacer size={32} />
        <Typography variant="h5">Date of Death</Typography>
        <Spacer size={8} />
        <Typography variant="body1">
          {moment(form.values.dateOfDeath!).format('MMMM D, YYYY')}
        </Typography>
        <Spacer size={16} />
        <MemberList title="Deceased" members={deceasedMembers} />
      </section>
      {withdrawingMembers.length > 0 && (
        <section>
          <Spacer size={32} />
          <Typography variant="h5">Enrollment End Date</Typography>
          <Spacer size={8} />
          <Typography variant="body1">
            {moment(form.values.enrollmentEndDate!).format('MMMM D, YYYY')}
          </Typography>
          <Spacer size={16} />
          <MemberList title="Withdrawing" members={withdrawingMembers} />
        </section>
      )}
    </>
  );
};
